import React, { useRef, useEffect, useState } from "react";

const SpanComp = ({
  item,
  setText,
  widgetId,
  index,
  handleSpanChange,
  col = null,
  default_widget_colors,
  mainDimensions1,
  isVisible,
  universalStyles,
  type,
  setItemIndexTypeCol,
  isColumn = true,
}) => {
  const spanRef = useRef(null);
  const [size, setSize] = useState({ height: item.height, width: item.width });

  useEffect(() => {
    const observer = new ResizeObserver(([entry]) => {
      const { height, width } = entry.contentRect;

      setSize((prev) => {
        if (prev.height === height && prev.width === width) return prev;
        return { height, width };
      });

      setText((prev) =>
        prev.map((itm, i) => (i === index ? { ...itm, height, width } : itm))
      );
    });

    if (spanRef.current) observer.observe(spanRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <span
      ref={spanRef}
      id={`${widgetId.toString()}_headingSpan`}
      role="textbox"
      data-placeholder={`${type}...`}
      contentEditable
      onBlur={(e) => {
        setText((prev) =>
          prev.map((itm, i) =>
            i === index ? { ...itm, text: e.target.innerText } : itm
          )
        );
      }}
      onInput={(e) => handleSpanChange(e, col, index, type)}
      onClick={(e) => {
        setItemIndexTypeCol && setItemIndexTypeCol({ index, type, col });
        e.stopPropagation();
      }}
      suppressContentEditableWarning
      style={{
        fontSize: Number(item.fontSize),
        color:
          item.isDefaultColor && type === "text"
            ? default_widget_colors.text.color
            : item.isDefaultColor && type === "heading"
            ? default_widget_colors.heading.color
            : item.isDefaultColor && type === "paragraph"
            ? default_widget_colors.paragraph.color
            : item.color,
        textDecorationLine: item.isUnderlined ? "underline" : "none",
        fontWeight: item.isBold ? "bold" : "normal",
        fontStyle: item.isItalic ? "italic" : "normal",
        fontFamily:
          item.family === "default"
            ? universalStyles.font
            : item.family || "sans-serif",
        borderColor:
          widgetId == localStorage.getItem("customId")
            ? "#BE9494"
            : "transparent",
        resize:
          widgetId == localStorage.getItem("customId") && !isVisible.heading
            ? "both"
            : "none",
        backgroundColor: "transparent",
        overflow: "hidden",
        maxHeight: mainDimensions1.height,
        maxWidth: isColumn
          ? mainDimensions1.width / 3 - 30
          : mainDimensions1.width,
        height: size.height,
        width: size.width,
        pointerEvents:
          widgetId == localStorage.getItem("customId") ? "" : "none",
        textAlign: item.direction || "left",
      }}
    >
      {item.text}
    </span>
  );
};

export default SpanComp;
